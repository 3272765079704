

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { routeStack, RouteWrapper } from "../services/routeStack.service";
import { Route } from "vue-router";

class BreadcrumbItem {
  link: string;
  name: string;
}

@Component({
  components: {},
})
export default class BreadcrumbsCpt extends AppVue {
  @Prop({ default: () => [] }) breadcrumbs: RouteWrapper[]; // this prop is reactive unless it is not.
  rs = routeStack; // to allow vue reactivity to work.
  async created() {
    routeStack.push(this.$route);
  }

  async mounted() {}
}

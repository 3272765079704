import { render, staticRenderFns } from "./ProjectResultsNavigationCpt.vue?vue&type=template&id=6654a042&scoped=true&"
import script from "./ProjectResultsNavigationCpt.vue?vue&type=script&lang=ts&"
export * from "./ProjectResultsNavigationCpt.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectResultsNavigationCpt.vue?vue&type=style&index=0&id=6654a042&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6654a042",
  null
  
)

export default component.exports



















import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ConfirmDialogCpt extends AppVue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop() title: string;
  @Prop() message: string;

  get isOpen() {
    return this.showDialog;
  }

  close() {
    this.closeResult(false);
  }

  closeResult(result: boolean) {
    this.$emit("close", result);
  }
}

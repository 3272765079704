var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[( !!_vm.projectId && !!_vm.companyId)?_c('div',{staticClass:"to-project cache-navigation"},[_c('router-link',{attrs:{"to":{
      name: 'CompanyDetail',
      params: { 
        companyId : _vm.companyId,
      },
    }}},[_c('el-tooltip',{staticClass:"item hide-on-small",attrs:{"effect":"dark","open-delay":2000,"content":"Back to search results","placement":"bottom-start"}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-back","round":""}},[_vm._v(" Back to company ")])],1),_c('el-button',{staticClass:"show-on-small",attrs:{"size":"small","icon":"el-icon-back","round":""}},[_vm._v(" Company ")])],1)],1):_vm._e(),(_vm.isVisible && _vm.cachedSearchRoute && !_vm.companyId)?_c('div',{staticClass:"cache-navigation"},[_c('router-link',{attrs:{"to":{
      name: _vm.cachedSearchRoute.name,
      params: Object.assign({}, _vm.cachedSearchRoute.params, {clearCache: '0'}),
    }}},[_c('el-tooltip',{staticClass:"item hide-on-small",attrs:{"effect":"dark","open-delay":2000,"content":"Back to search results","placement":"bottom-start"}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-back","round":""}},[_vm._v(" Back to results ")])],1),_c('el-button',{staticClass:"show-on-small",attrs:{"size":"small","icon":"el-icon-back","round":""}},[_vm._v(" Results ")])],1),(_vm.prevProjectId > 0)?_c('router-link',{attrs:{"to":{
      name: 'Project',
      params: { projectId: _vm.prevProjectId },
    }}},[_c('el-tooltip',{staticClass:"item hide-on-small",attrs:{"effect":"dark","open-delay":2000,"content":"Previous project in search results","placement":"bottom-start"}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-arrow-left","round":""}},[_c('span',{staticClass:"hide-on-small"},[_vm._v(" Previous Project "),(_vm.showProjectTitle)?_c('span',{staticClass:"project-title"},[_vm._v("("+_vm._s(_vm.prevProject.document.title)+")")]):_vm._e()])])],1),_c('el-button',{staticClass:"show-on-small",attrs:{"size":"small","icon":"el-icon-arrow-left","round":""}},[_c('span',{staticClass:"hide-on-small"},[_vm._v(" Previous result ")])])],1):_vm._e(),(_vm.nextProjectId > 0)?_c('router-link',{attrs:{"to":{
      name: 'Project',
      params: { projectId: _vm.nextProjectId },
    }}},[_c('el-tooltip',{staticClass:"item hide-on-small",attrs:{"effect":"dark","open-delay":2000,"content":"Next project in search results","placement":"bottom-start"}},[_c('el-button',{attrs:{"size":"small","round":""}},[_c('span',{staticClass:"hide-on-small"},[_vm._v(" Next Project "),(_vm.showProjectTitle)?_c('span',{staticClass:"project-title"},[_vm._v("("+_vm._s(_vm.nextProject.document.title)+")")]):_vm._e()]),_c('i',{staticClass:"el-icon-arrow-right"})])],1),_c('el-button',{staticClass:"show-on-small",attrs:{"size":"small","round":""}},[_c('span',{staticClass:"hide-on-small"},[_vm._v(" Next result ")]),_c('i',{staticClass:"el-icon-arrow-right"})])],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
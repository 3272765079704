
































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class FooterCpt extends AppVue {
  get year() {
    return new Date().getFullYear();
  }
}

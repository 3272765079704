

















import AppVue from "../../AppVue.vue";
import { Component, Prop } from "vue-property-decorator";
import { UserForgetProjectService } from "@/core/services";
import BlockIcon from "vue-material-design-icons/BlockHelper.vue";

@Component({
  components: {
    BlockIcon,
  },
})
export default class ForgetProjectButtonCpt extends AppVue {
  @Prop({ default: false }) projectId: number;

  get ForgetText() {
    return this.forgetProjectMessage;
  }

  async forgetProject() {
    try {
      await UserForgetProjectService.createUserForgetProject(
        Number(this.projectId),
      );
      this.notify(
        "Project forgotten",
        this.forgetProjectMessage,
        "success",
        true,
      );
      this.$emit("projectForgotten", { projectId: this.projectId });
    } catch (x) {
      let ex = x as string;
      if (ex == "Error: Request failed with status code 400") {
        this.notify(
          "Limit reached",
          this.maxForgottenProjectsReachedMessage,
          "warning",
          true,
        );
      } else {
        this.notify("Error", ex, "error", true);
      }
    }
  }
}




























import { Component, Prop } from "vue-property-decorator";
import { AccountService } from "@/core/services";
import AppVue from "@/AppVue.vue";

@Component
export default class PrivacyPolicyCpt extends AppVue {
  @Prop({ default: false }) visible: boolean;

  isPrivacyPolicyAccepted = true;
  loading = false;

  async created() {
    // await this.ensurePrivacyAccepted();
  }

  async ensurePrivacyAccepted() {
    this.isPrivacyPolicyAccepted = await AccountService.isPrivacyPolicyAccepted();
  }

  async acceptPolicy() {
    this.loading = true;
    await AccountService.acceptPrivacyPolicy();
    this.close();
    this.loading = false;
  }

  close() {
    this.isPrivacyPolicyAccepted = true;
    this.$emit("close");
  }
}

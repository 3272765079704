








import { Component, Vue, Prop } from "vue-property-decorator";

import { LookupsService } from "@/core/services";
import { SimpleLookupModel } from "@/core/models";

@Component
export default class LookupValueCpt extends Vue {
  @Prop() label: string;
  @Prop() lookups: SimpleLookupModel[];
  @Prop() lookupId: number;

  get lookupText() {
    if (!this.lookups || this.lookups.length === 0) {
      // If lookups haven't loaded yet
      return "";
    }

    return LookupsService.findLookupText(this.lookups, this.lookupId) ||
           LookupsService.findLookupName(this.lookups, this.lookupId) ||
           "N/A";
  }
}
























import { Component, Prop } from "vue-property-decorator";
import { AccountService } from "@/core/services";
import AppVue from "@/AppVue.vue";

@Component
export default class FirstOpeningQuestionnaireDialogCpt extends AppVue {
  hasStartedQuestionnaire = true;

  async created() {
    await this.ensurePrivacyAccepted();
  }

  async ensurePrivacyAccepted() {
    this.hasStartedQuestionnaire = await AccountService.hasStartedQuestionnaire();
    await AccountService.startQuestionnaire();
  }

  close() {
    this.hasStartedQuestionnaire = true;
  }
}
















import { Component, Vue } from "vue-property-decorator";
import { AccountService, AuthTokenService, CurrentProfile, SearchService, WatcherService } from "@/core/services";
import PrivacyPolicyCpt from "./PrivacyPolicyCpt.vue";
import AppVue from "@/AppVue.vue";
import userflow from "userflow.js";
import { accountRoutes } from "@/modules/account/routes";
import { zohoManager, ZohoManager } from "@/zoho/zoho";

@Component({
  components: {
    PrivacyPolicyCpt,
  },
})
export default class AuthStatusCpt extends AppVue {
  isAuthenticated = false;
  userName = "";
  emailHash = "";
  name = "";
  initials = "";
  /* tslint:disable:no-bitwise */
  get avatarColour() {
    let hash = 0;
    for (let i = 0; i < this.emailHash.length; i++) {
      hash = this.emailHash.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }
  /* tslint:enable:no-bitwise */

  get imageTitle() {
    return `${this.userName}`;
  }

  async created() {
    await this.setData();
    this.$eventHub.$on("USER_UPDATED", this.setData);
  }
  destroyed() {
    this.$eventHub.$off("USER_UPDATED", this.setData);
  }
  changePassword() {
    this.$router.push("/auth/changePassword").catch(() => {});
  }

  showDrawer() {
    this.$emit("open");
  }
  get isImpersonating() {
    return AuthTokenService.isImpersonating();
  }
  async logout() {
    await AccountService.logout();
    if (this.isImpersonating) {
      SearchService.clearCache();
      WatcherService.clearCache();
      await AccountService.restoreToken();
      this.notify("Success", "Impersonation terminated.", "success");
      this.$router
        .push("/admin/users/search")
        .then(() => {
          window.location.reload();
        })
        .catch(() => {});
    } else {
      this.notify("Success", "You are now logged out.", "success");
      SearchService.clearCache();
      WatcherService.clearCache();
      this.$router.push("/login").catch(() => {});
    }
  }

  private async setData() {
    this.isAuthenticated = this.$auth.isAuthenticated();
    if (this.isAuthenticated) {
      if (!CurrentProfile.currentProfile || !CurrentProfile.currentProfile.name) {
        const profile = await AccountService.getProfile();
        this.initials = `${profile.name[0]}${profile.lastname[0]}`;
      } else {
        this.initials = `${CurrentProfile.currentProfile.name[0]}${CurrentProfile.currentProfile.lastname[0]}`;
      }
      const p = this.$auth.getPayload();
      this.userName = p["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
      this.emailHash = p.EmailHash;
      if (this.isUserflowEnabled) {
        const load = this.$auth.getPayload() as any;
        const codes = load["PubCode"];
        const firstCode = (Array.isArray(codes) ? codes : [codes])[0];
        userflow.init(this.userflowToken);
        userflow.identify(this.currentUserId.toString(), {
          email: this.userName,
          pubCode: firstCode,
        });
      }
    }
  }
}























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { SearchService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { routeStack, RouteWrapper } from "../services/routeStack.service";
import { Route } from "vue-router";

@Component({
  components: {},
})
export default class ProjectResultsNavigationCpt extends AppVue {
  @Prop() projectId: number;
  @Prop() companyId: number;

  showProjectTitle = true;
  get cachedSearchRoute() {
    return SearchService.projectSearchCache.currentRoute;
  }
  get currentIndex() {
    // set it as selected!
    const currentResult = SearchService.findInCache(this.projectId);
    if (currentResult === -1) {
      return -1;
    }
    SearchService.projectSearchCache.model.results.forEach((x) => (x.document.selected = false));
    SearchService.projectSearchCache.model.results[currentResult].document.selected = true;
    this.$eventHub.$emit("openDetailView");
    return currentResult;
  }
  get totalResults() {
    return SearchService.projectSearchCache!.model!.results!.length || 100;
  }
  get isVisible() {
    return SearchService.isProjectSearchCached();
  }
  get prevProjectId() {
    if (this.prevProject) {
      return this.prevProject.document.projectId;
    }
    return 0;
  }
  get nextProjectId() {
    if (this.nextProject) {
      return this.nextProject.document.projectId;
    }
    return 0;
  }
  get prevProject() {
    if (this.currentIndex > 0) {
      return SearchService.projectSearchCache.model.results[this.currentIndex - 1];
    }
    return null;
  }
  get nextProject() {
    if (this.currentIndex < SearchService.projectSearchCache.model.results.length) {
      return SearchService.projectSearchCache.model.results[this.currentIndex + 1];
    }
    return null;
  }
  async mounted() {}
  async updated() {}
  async created() {}
}

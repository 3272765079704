



















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { SearchService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { routeStack, RouteWrapper } from "../services/routeStack.service";
import { Route } from "vue-router";

@Component({
  components: {},
})
export default class CompanyResultsNavigationCpt extends AppVue {
  @Prop() companyId: number;
  @Prop() projectId: number;
  @Prop({ default: true }) showNavigation: boolean;
  showProjectTitle = true;

  get cachedSearchRoute() {
    return SearchService.companySearchCache.currentRoute;
  }
  get currentIndex() {
    // set it as selected!
    const currentResult = SearchService.findInCompanyCache(this.companyId);
    if (currentResult === -1) {
      return -1;
    }
    SearchService.companySearchCache.model.results.forEach((x) => (x.document.selected = false));
    SearchService.companySearchCache.model.results[currentResult].document.selected = true;
    this.$eventHub.$emit("openDetailView");
    return currentResult;
  }
  get totalResults() {
    return SearchService.companySearchCache!.model!.results!.length || 100;
  }
  get isVisible() {
    return SearchService.isCompanySearchCached();
  }
  get prevCompanyId() {
    if (this.prevCompany) {
      return this.prevCompany.document.companyId;
    }
    return 0;
  }
  get nextCompanyId() {
    if (this.nextCompany) {
      return this.nextCompany.document.companyId;
    }
    return 0;
  }
  get prevCompany() {
    if (this.currentIndex > 0) {
      return SearchService.companySearchCache.model.results[this.currentIndex - 1];
    }
    return null;
  }
  get nextCompany() {
    if (this.currentIndex < SearchService.companySearchCache.model.results.length) {
      return SearchService.companySearchCache.model.results[this.currentIndex + 1];
    }
    return null;
  }
  async mounted() {}
  async updated() {}
  async created() {}
}











import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class BlankSlateCpt extends AppVue {
  @Prop() title: string;
  @Prop() message: string;
  @Prop() link: string;
  @Prop({ default: "" }) className: string;
  @Prop({ default: false }) nomargin: boolean;

  get marginClass() {
    return this.nomargin ? "nomargin" : "margin";
  }
  get myClass() {
    return `${this.className} blank-slate ${this.marginClass}`;
  }
}

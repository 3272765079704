


























































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";

import AppVue from "@/AppVue.vue";
import moment from "moment";
import { truncateFilter } from "@/core/filters";
import { b$ } from "@/core/filters/beautifier";
@Component({
  components: {},
})
export default class LabeledSectionCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "New label" }) label: string;
  @Prop({ default: "New value" }) value: string;
  @Prop({ default: "key" }) icon: string;
  @Prop({ default: "text" }) type: string;
  @Prop({ default: true }) hideIfEmpty: boolean;
  b$ = b$;
  showFullDescription = false;

  get isLoading() {
    return this.loading;
  }

  get isHidden() {
    return this.hideIfEmpty && !this.value;
  }

  get truncatedText() {
    return this.b$(truncateFilter(this.value, 500), "em");
  }
  get fullText() {
    return this.b$(this.value, "em");
  }
  get isTruncated() {
    return this.value.length > 500;
  }

  async created() {}

  async mounted() {}
}
